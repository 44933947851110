<template>
	<div id="home">
		欢迎使用云享库系统！
		<!-- <img src="@/assets/img/temp_home.png" alt=""> -->
	</div>
</template>

<script type="text/javascript">
	import {
		getCurrentInstance,
		defineComponent,
		ref,
		reactive
	} from 'vue'
	export default defineComponent({
		name: 'Home',
		setup() {
			// 访问 globalProperties
			const ctx = getCurrentInstance().appContext.config.globalProperties
		}
	})
</script>

<style lang="less">
	#home {
		img {
			width: 100%;
		}
	}
</style>
